@import url('https://fonts.googleapis.com/css2?family=Jura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@700&display=swap');


* {
    box-sizing: border-box;
    font-family: 'Jura', sans-serif;
    margin: 0;
    overflow: hidden;
}

.container {
    padding: 22px;
    overflow: hidden;
}

body {
    font-family: 'Jura', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    background-color: #1e1e1e;
}

.search-bar {
    display: flex;
    flex-direction: row;
}

.search-button {
    outline: none;
    border: none;
    background-color: #323232;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 14px;
}

.searchField {
    border: none;
    outline: none;
    padding: 14px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 12px;
    color: white;
    width: 100%;
    margin-right: 5px;
    background-color: #323232;
}

.layout {
    padding: 20px;
    width: 100%;
    max-width: 482px;
}

.tab {
    display: flex;
    flex-direction: row;
    background-color: #323232;
    padding: 6px;
    border-radius: 16px;
    margin-top: 14px;
}

.tab-item-selected {
    width: 50%;
    display: flex;
    justify-content: center;
    background-color: #37474f;
    border-radius: 12px;
    padding: 10px;
}

.tab-item {
    width: 50%;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    padding: 10px;
}

.tab-item-selected h4 {
    color: white;
}

.tab-item h4 {
    color: white;
}

code {
    width: 100%;
}

.content {
    height: 80vh;
    overflow: scroll;
    margin-top: 10px;
    padding-bottom: 10vh;
    overflow-x: hidden;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
}

.content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.content-whale {
    max-height: 83vh;
    overflow: scroll;
    margin-top: 10px;
    padding-bottom: 10vh;
    overflow-x: hidden;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
}

.content-whale::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}


.token {
    margin: 5px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    background-color: #323232;
    height: 110px;
    overflow-x: hidden;
    /*border: 2px solid white;*/
}

.n-amt {
    position: absolute;
    bottom: 9px;
    font-size: 10px;
}

.token .icon {
    background-color: white;
    height: 35px;
    width: 35px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.token-icon {
    height: 35px;
    width: 35px;
    margin-top: -5px;
    margin-bottom: 5px;
}

.token .icon p {
    color: black;
    font-weight: bold;
    font-size: 25px;
}

.token h4, .token h5, .token p {
    color: white;
}


.whale {
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    background-color: #323232;
}

.whale h3 {
    color: white;
    font-size: 15px;
}

.whale h4 {
    font-size: 12px;
    color: white;
    margin-top: 5px;
}


.info {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 25px;
}

.popup {
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
}

.action {
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 80%;
    border-radius: 12px;
    align-items: center;
}

.actionInput {
    border: none;
    outline: none;
    padding: 14px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 12px;
    margin-right: 5px;
    width: 58vw;
    color: white;
    background-color: #323232;
    /*margin-top: 16px;*/
}

.actionInputError {
    border: 2px solid red;
    outline: none;
    padding: 14px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 12px;
    margin-right: 5px;
    width: 100%;
    color: white;
    background-color: #323232;
    margin-top: 16px;
}

.errorMsg {
    font-size: 12px;
    color: red;
    text-align: center;
    margin-top: 5px;
}

.actionButton {
    outline: none;
    border: none;
    background-color: #37474f;
    color: white;
    font-size: 18px;
    padding: 10px;
    border-radius: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.clear-button {
    position: absolute;
    right: 0;
    bottom: 0;
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    background: #323232;
    padding: 10px;
    display: flex;
    width: 100%;
    flex-direction: row;
    border-top: 4px solid #37474f;
    justify-content: space-around;
}

.bottom-item {
    text-align: center;
}

.bottom-item h5 {
    color: white;
    margin-top: 10px;
}

.backToTokens {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.backToTokens p {
    color: white;
    margin-left: 5px;
}

.floating-button {
    position: absolute;
    bottom: 85px;
    right: 15px;
}

.floating-button h4 {
    font-weight: bolder;
    margin-left: 5px;
}

.whaleAddressInput {
    border: none;
    border-bottom: 2px solid white;
    outline: none;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    width: 100%;
    margin-top: 10px;
    background: none;
}

.selectTokens {
    margin-top: 10px;
    max-height: 40vh;
    overflow-y: scroll;
}

.selectToken {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    border-bottom: 1px solid #37474f;
}

.selectToken p {
    font-size: 14px;
    color: white;
}

.whaleToken {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.whaleToken p {
    color: white;
    font-size: 13px;
    margin-left: 10px;
}

.whaleToken input {
    border: none;
    outline: none;
    background: none;
    border-bottom: 2px solid #37474f;
    color: white;
    font-size: 14px;
    text-align: right;
    width: 20%;
}

.searchTokenField{
    width: 100%;
    border:none;
    border-bottom: 2px solid #37474f;
    background: none;
    outline: none;
    color: white;
    padding: 8px;
    margin-top: 10px;
}

.MuiChip-label {
    color: #fff !important;
}

.MuiSvgIcon-root {
    color: #fff !important;
}

.MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.2) !important;
}
